// import Box from "../components/box";
import React, { useEffect, useState } from 'react';
import Button from "../../components/Button";
import ButtonGroup from "../../components/buttonGroup";
import Pagination from "../../components/pagination";
import useFetchData from "../../api/useFetchData";
import { useStoreActions, useStoreState } from "easy-peasy";

function Commands() {

    const filter = useStoreState(state => state.commands.filter);
    const setFilter = useStoreActions(state => state.commands.setFilter);

    const statePage = useState(1)
    const page = statePage[0]

    const {data, error, loading, fetchGETData} = useFetchData('commands');

    const {data: userData, fetchPOSTData: createUser} = useFetchData('createuser');
    const {data: serverData, fetchPOSTData: createServer} = useFetchData('server/create');
    const {data: refusedCommandData, fetchPOSTData: refuseCommand} = useFetchData('refusecommand');

    useEffect(() => {
        fetchGETData({...filter, page: page})
    }, [fetchGETData, userData, serverData, refusedCommandData, filter, page]);

    const setFilterFromButtons = (id) => {
        setFilter(id)
    }

    return (
        <>
            <ButtonGroup 
                initialButtonClicked={filter.filter}
                buttons={[
                    {name: 'Commandes en cours', key: ''}, 
                    {name: 'Commandes livrées', key: 'LIVERED'}, 
                    {name: 'Commandes refusées', key: 'REFUSED'}
                ]}
                doSomethingAfterClick={setFilterFromButtons}
            />
            <Pagination block={loading} page={statePage} />

            <h1 className="text-2xl mb-5">Commandes totales : { data?.number || 0 } (dont { data?.data.length || 0 } affichées)</h1>
            { error && <h1>Une erreur est survenue</h1> }
            { loading && <h1>chargement...</h1> }

            <div className='flex flex-col gap-[20px]'>
                { data && data.data.map((command, i) =>
                    <div className='shadow-header flex flex-col bg-white p-[20px] gap-[15px] rounded-[5px]' key={command.id}>
                        <h1 className="text-xl">Commande n°{ command.id }</h1>

                        {!command.user.original && <h1 style={{color: "red"}}>IMPOSTEUR !</h1>}

                        <p>Utilisateur { command.author }</p>
                        <Button type="full" to={`/admin/users/${command.author}/`}>
                            Voir l'utilisateur
                        </Button>
                        <p>Offre: { command.product.name }</p>

                        
                        {
                        (command.state !== "LIVERED") && (command.user.pterodactyl
                            ?
                            <Button type="full" onClick={() => createServer(
                                {'id':command.id, 'user': command.user.pterodactyl})}>
                                Créer l'hébergement
                            </Button>
                            :
                            <Button type="full" onClick={() => createUser({'id':command.author})}>
                                Créer le compte de l'utilisateur
                            </Button>)
                        }
                        
                        {command.state !== "LIVERED" && <Button type="full" onClick={() => refuseCommand({id: command.id})}>Refuser</Button>}
                        
                        <details>
                            <summary>
                                Code
                            </summary>
                        <pre className="rounded-lg bg-[#e6e6e6] p-[10px] w-fit">{JSON.stringify(
                            Object.keys(command)
                            .filter(key => !key.includes('product'))
                            .reduce((cur, key) => { return Object.assign(cur, { [key]: command[key] })}, {})
                            , null, 2)}</pre>
                        </details>
                        
                    </div>
                )}
            </div>

            <Pagination page={statePage} />
        </>
    );
}

export default Commands;