import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useStoreState } from 'easy-peasy';
import {NavLink as Lnk} from 'react-router-dom';
import LeftBar from '../components/LeftBar';
import { useTranslation } from 'react-i18next';

function Offers() {
	const { t } = useTranslation();
	let navigate = useNavigate();

	const data = useStoreState(store => store.user.data)

	useEffect(() => {
		if(
			!localStorage.getItem('token') || 
			(data ? data?.grade : JSON.parse(localStorage.getItem('user') === "undefined" ? '{}' : localStorage.getItem('user'))?.grade) !== 1
		) navigate(-1)
	}, [navigate, data, data?.grade])

	const link = [
        {
            name: t('admin.dashboard'),
            url: "/admin/"
        },
        {
            name: t('admin.commands'),
            url: "/admin/commands/"
        },
        {
            name: t('admin.users'),
            url: "/admin/users/"
        }
    ]

	return (
		<div className="flex flex-col App min-h-screen bg-background font-outfit">
			<Header />
			<div className='grow flex'>
				<LeftBar>
					<div className='flex h-full flex-col gap-[20px]'>
						<div className='flex flex-col py-[5px] gap-[10px]'>
							<Lnk className={({isActive}) => `${isActive ? 'bg-primary' : 'hover:bg-red-600'} h-fit text-white no-underline px-[10px] py-[15px]`} to='/profile/'>{t('admin.back')}</Lnk>
						</div>
						<h1 className="px-[10px] font-medium text-white text-[24px]">{t('admin.title')}</h1>
						<div className='flex grow flex-col py-[5px] gap-[10px]'>
							{link.map(link => <Lnk className={({isActive}) => `${isActive ? 'bg-primary' : 'hover:bg-text-light'} h-fit text-white no-underline px-[10px] py-[15px]`} key={link.url} to={link.url}>{link.name}</Lnk>)}
						</div>
					</div>
				</LeftBar>
				<div className='h-[calc(100vh-100px)] w-full overflow-y-scroll p-[20px] pl-[10px] gap-[20px] flex flex-col'>
					<Outlet />
				</div>
			</div>
		</div>
	  );
}

export default Offers;
