import React, { useEffect, useState } from "react";

const ButtonGroup = ({ initialButtonClicked, buttons, doSomethingAfterClick }) => {

  const [clickedId, setClickedId] = useState();

  let index = (buttons.findIndex((b) => b.key === initialButtonClicked));

  useEffect(() => {
    setClickedId(index);
  }, [initialButtonClicked, index]);

  const handleClick = (event, id, buttonKey) => {
    setClickedId(id);
    doSomethingAfterClick(buttonKey ?? id);
  };

  return (
    <div className="divide-x divide-custom-blue">
      {buttons.map((buttonLabel, i) => (
        <button
          key={i}
          name={buttonLabel.name}
          onClick={(event) => handleClick(event, i, buttonLabel.key)}
          className={((i === buttons.length-1) ? "rounded-r-lg !border-[1px]" : '') + (i===0 ? " rounded-l-lg border-l-[1px]" : '') + " py-1 px-3 transition-[0.2s] border-y-[1px] border-primary text-primary " + ((i === clickedId) ? "bg-primary !text-[white]" : '')}
        >
          {buttonLabel.name}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;