import Admin from '../page/Admin';

import Commands from '../page/admin/Commands';
import Dashboard from '../page/admin/Dashboard';
import Users from '../page/admin/Users';
import User from '../page/admin/User';
import NotFound from '../page/NotFound';

const routes = {
    path: '/admin',
    element: <Admin />,
    children: [
        {
        path: '/admin/*',
        element: <NotFound />
        },
        {
        path: '/admin/',
        element: <Dashboard />
        },
        {
            path: '/admin/commands',
            element: <Commands />
        },
        {
            path: '/admin/commands/:id/',
            element: <Commands />
        },
        {
            path: '/admin/users',
            element: <Users />
        },
        {
            path: '/admin/users/:id/',
            element: <User />
        },
    ]
}

export default routes;