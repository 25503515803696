import React from 'react';

function Footer(props) {
    return (
        <footer className='bg-text shadow-header w-full'>
            <h1 className='text-text-light font-[300]'>
                Footer
            </h1>
        </footer>
    );
}

export default Footer;