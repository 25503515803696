import React from 'react';

function Pagination({page, block}) {
    
    return (
        <div className="flex">
            <button className={"active:bg-primary active:text-[white] rounded-l-lg border-[1px] border-primary py-1 px-3 transition-[0.2s] text-primary"} onClick={() => {if(!block) return page[1](Math.max(1, page[0]-1))}}>{"<-"}</button>
            <div className={"border-y-[1px] border-primary w-[40px] text-center bg-primary py-1 px-3 transition-[0.2s] text-[white]"}>{page[0]}</div>
            <button className={"active:bg-primary active:text-[white] rounded-r-lg border-[1px] border-primary py-1 px-3 transition-[0.2s] text-primary"} onClick={() => {if(!block) return page[1](page[0]+1)}}>{"->"}</button>
        </div>
    );
}

export default Pagination;