import { useEffect } from 'react';
import useFetchData from '../api/useFetchData';
import Loader from '../components/Spinner';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Link from '../components/Link';
import Button from '../components/Button';

function Offers() {
	let navigate = useNavigate();
	const { t } = useTranslation();

	const { data, error, loading, fetchGETData, overrideData } =
		useFetchData('offers');
	const { id } = useParams();

	useEffect(() => {
		if (!id) {
			fetchGETData();
		} else {
			fetchGETData({ id });
		}
	}, [fetchGETData, id]);

	useEffect(() => {
		console.log(data);
	}, [data]);

	return (
		<div className="p-[20px]">
			{!id ? (
				<h1>Catégories</h1>
			) : (
				<>
					<Button onClick={() => navigate(-1)}>{t('back')}</Button>
					<h1>Offres</h1>
				</>
			)}
			{loading ? (
				<Loader />
			) : error ? (
				<p>{t('error')}</p>
			) : (
				<div className="flex flex-col gap-[20px]">
					{data &&
						(!id
							? data.map((category) => (
									<div
										key={category.id}
										onClick={() => overrideData([])}
									>
										<Link to={category.id + '/'}>
											{category.name}
										</Link>
									</div>
							  ))
							: data.map((offer) => (
									<div key={offer.id}>
										<a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">
											{offer.name}
										</a>
									</div>
							  )))}
				</div>
			)}
		</div>
	);
}

export default Offers;
