import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import useFetchData from '../../api/useFetchData';
import Spinner from '../../components/Spinner';

function Account(props) {
    const { t } = useTranslation();

    const { data, error, loading, fetchGETData } = useFetchData('account/products');

    useEffect(() => {
        fetchGETData();
    }, [fetchGETData])

    return (
        <>
            <h1 className='text-[36px] font-bold text-primary'>{t('profile.page.products')}</h1>
            <div className='flex gap-[20px]'>
                <div className='flex gap-[10px] flex-col'>
                { loading ? <Spinner /> : error ? (
				    <p>{t('error')}</p>
			    ) : data && (data.data.map(p => 
                    <div className='shadow-header flex flex-col bg-white p-[20px] gap-[15px] rounded-[5px]'>
                        {JSON.stringify(p)}
                    </div>
                    )
                )}
                </div>
            </div>
        </>
    );
}

export default Account;