import React, { useEffect, useState } from 'react';
import Pagination from "../../components/pagination";
import useFetchData from "../../api/useFetchData";
import Button from '../../components/Button';
import Search from '../../components/Search';

function Users() {

    const statePage = useState(1)
    const page = statePage[0]

    const [filter, setFilter] = useState('') 

    const {data, error, loading, fetchGETData} = useFetchData('users');

    useEffect(() => {
        fetchGETData({filterBy: 'username', filter, page: page})
    }, [fetchGETData, page, filter]);

    return (
        <>
            <div className='flex justify-between'>
                <Pagination block={loading} page={statePage} />
                <Search onSearch={setFilter}/>
            </div>

            <h1 className="text-2xl mb-5">Utilisateurs totales : { data?.number || 0 } (dont { data?.data.length || 0 } affichées)</h1>
            { error && <h1>Une erreur est survenue</h1> }
            { loading && <h1>chargement...</h1> }

            <div className='flex flex-col gap-[20px]'>
                { data && data?.data.map((user, i) =>
                    <div className='shadow-header flex flex-col bg-white p-[20px] gap-[15px] rounded-[5px]' key={user.id}>
                        <h1>{user.name}</h1>
                        <Button type="full" to={`/admin/users/${user.id}/`}>
                            Voir l'utilisateur
                        </Button>
                        <details>
                            <summary>
                                Code
                            </summary>
                        <pre className="rounded-lg bg-[#e6e6e6] p-[10px] w-fit">{JSON.stringify(user, null, 4)}</pre>
                        </details>
                        
                    </div>
                )}
            </div>

            <Pagination page={statePage} />
        </>
    );
}

export default Users;