import Button from '../components/Button';
import { ReactComponent as Logo } from '../assets/logo.svg'
import { useTranslation } from 'react-i18next';

function Homepage() {

    const { t } = useTranslation();

    return (
        <>
            <div className="relative w-4/5 m-auto flex items-center justify-between min-h-[calc(100vh-100px)]">
                <div className='z-10 m-auto lg:m-0 flex flex-col gap-[20px]'>
                    <h1 className="text-[32px] w-max xl:text-[128px] text-text whitespace-pre">
                        {t('homepage.moto.first')}<br/>
                        {t('homepage.moto.second')}<br/>
                        {t('homepage.moto.third')}
                    </h1>
                    <Button to="/signin" big={true} type='full'>{t('homepage.link')}</Button>
                </div>
                <Logo className='pointer-events-none select-none z-0 left-1/2 -translate-x-1/2 lg:transform-none lg:m-0 absolute lg:static max-h-[80%] w-full lg:w-auto fill-[rgba(0_0_0_/_5%)]'/>
            </div>
        </>
    );
}

export default Homepage;
