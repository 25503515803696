import { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import useFetchData from './useFetchData';
import { useNavigate } from 'react-router-dom';

const useUserData = () => {
    const { data, error, fetchGETData } = useFetchData('account/auth');

    const setUserData = useStoreActions((action) => action.user.setUserData)

    let location = useNavigate();

    useEffect(() => {
        fetchGETData()
    }, [location, fetchGETData])

    useEffect(() => {
        if (error.code === 404) {
            localStorage.removeItem('token')
            console.log('token cleared');
        }
        setUserData(data?.data)
        localStorage.setItem('user', JSON.stringify(data?.data))
    }, [data?.data, error.code, setUserData])
};

export default useUserData;