import React from 'react';

function LeftBar(props) {
    return (
        <div className='overflow-hidden min-w-[310px]'>
            <div className='w-[calc(100%-10px)] py-[20px] h-full bg-text relative before:w-[20px] before:h-[20px] before:rounded-tl-[20px] before:border-t-[10px] before:border-l-[10px] before:bg-transparent before:border-solid before:border-text before:absolute before:-right-[10px] before:-top-[10px] after:bg-transparent after:w-[20px] after:h-[20px] after:rounded-bl-[20px] after:border-b-[10px] after:border-l-[10px] after:bg-transparent after:border-solid after:border-text after:absolute after:-right-[10px] after:-bottom-[10px]'>
                {props.children}
            </div>
        </div>
    );
}

export default LeftBar;