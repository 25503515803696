import React, { useEffect, useState } from 'react';
import Pagination from "../../components/pagination";
import useFetchData from "../../api/useFetchData";
import { useParams } from 'react-router-dom';

function Users() {

    const id = parseInt(useParams().id);

    const {data, error, loading, fetchGETData} = useFetchData('users/get');

    useEffect(() => {
        fetchGETData({id})
    }, [fetchGETData, id]);

    return (
        <>

            <h1 className="text-2xl mb-5">Utilisateur</h1>
            { error && <h1>Une erreur est survenue</h1> }
            { loading && <h1>chargement...</h1> }
            { data && <>
            <div className='flex gap-[10px] flex-col'>
                <h1 className='font-medium text-[36px] text-text'>{data?.data.name}</h1>
                <div className='shadow-header flex flex-col bg-white p-[20px] gap-[15px] rounded-[5px]'>
                    {JSON.stringify(data?.data)}
                </div>
            </div>
            <div className='flex gap-[10px] flex-col'>
                <h1 className='font-medium text-[36px] text-text'>Commandes</h1>
                <div className='shadow-header flex flex-col bg-white p-[20px] gap-[15px] rounded-[5px]'>
                    {data?.data.commands.map(c => <>
                        {JSON.stringify(c)}
                    </>)}
                </div>
            </div>
            </> }
        </>
    );
}

export default Users;