import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LeftBar from '../components/LeftBar';
import { Outlet } from 'react-router-dom';
import {NavLink as Lnk} from 'react-router-dom';
import Header from '../components/Header';
import { useStoreState } from 'easy-peasy';

function Offers() {
	let navigate = useNavigate();
	const { t } = useTranslation();

	const data = useStoreState(store => store.user.data)

	useEffect(() => {
		if(!localStorage.getItem('token')) navigate(-1)
	}, [navigate])

	const link = [
        {
            name: t('profile.link.account'),
            url: "/profile/"
        },
        {
            name: t('profile.link.products'),
            url: "/profile/products/"
        },
        {
            name: t('profile.link.tickets'),
            url: "/profile/tickets/"
        },
        {
            name: t('profile.link.invoices'),
            url: "/profile/invoices/"
        }
    ]

	return (
		<div className="flex flex-col App min-h-screen bg-background font-outfit">
			<Header />
			<div className='grow flex'>
				<LeftBar>
					<div className='flex h-full flex-col gap-[20px]'>
						<img className="w-[200px] h-[200px] m-auto rounded-full bg-primary object-cover" alt="profile" src="https://media.tenor.com/_4YgA77ExHEAAAAd/rick-roll.gif"/>	
						<div className='mx-[20px]'>
							<h1 className="font-medium text-white text-[24px]">{data?.name ?? null}</h1>
							<h2 className="font-medium text-text-light">{data?.email ?? null}</h2>
						</div>
						<div className='flex grow flex-col py-[5px] gap-[10px]'>
							{link.map(link => <Lnk className={({isActive}) => `${isActive ? 'bg-primary' : 'hover:bg-text-light'} h-fit text-white no-underline px-[10px] py-[15px]`} key={link.url} to={link.url}>{link.name}</Lnk>)}
						</div>
						<div className='flex flex-col py-[5px] gap-[10px]'>
							<Lnk className={({isActive}) => `${isActive ? 'bg-primary' : 'hover:bg-red-600'} h-fit text-white no-underline px-[10px] py-[15px]`} to='/logout/'>{t('profile.link.logout')}</Lnk>
						</div>
					</div>
				</LeftBar>
				<div className='h-[calc(100vh-100px)] w-full overflow-y-scroll p-[20px] pl-[10px] gap-[20px] flex flex-col'>
					<Outlet />
				</div>
			</div>
		</div>
	);
}

export default Offers;
