import React, { useState } from 'react';
import Link from './Link';
import {Link as Lnk} from 'react-router-dom';
import Button from './Button';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { useTranslation } from 'react-i18next';

function Header() {

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const link = [
        {
            name: t('header.link.offers'),
            url: "/offers/"
        },
        {
            name: t('header.link.ressources'),
            url: "/ressources/"
        },
        {
            name: t('header.link.api'),
            url: "/api/"
        },
        {
            name: t('header.link.jobs'),
            url: "/jobs/"
        }
    ]

    let connected = localStorage.getItem('token');

    return (
        <>
        <header className='z-30 bg-white shadow-header top-0 sticky w-full'>
            <div className='w-4/5 h-[100px] m-auto flex justify-between items-center'>
                <Lnk to="/" className='no-underline flex gap-[10px] lg:gap-[20px] items-center'>
                    <Logo className='w-[50px] h-[50px] fill-primary-normal'/>
                    <h1 className='text-text text-[24px] font-medium'>Project Heberg</h1>
                </Lnk>
                <div className='hidden lg:flex gap-[20px] items-center'>
                    {link.map(link => <Link key={link.url} to={link.url}>{link.name}</Link>)}
                </div>
                { !connected
                ?
                <div className='hidden lg:flex gap-[20px] items-center'>
                    <Link to="/login/">{t('header.profile.login')}</Link>
                    <Button to="/signin/">{t('header.profile.signin')}</Button>
                </div>
                :
                <div className='hidden lg:flex gap-[20px] items-center'>
                    <Button to="/profile/">{t('header.profile.profile')}</Button>
                </div>
                }
                <svg onClick={() => setOpen(!open)} className="lg:hidden h-6 w-6 fill-current" viewBox="0 0 24 24">
                    {open
                    ? 
                    <path fillRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                    :
                    <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
                    }
                </svg>
            </div>
        </header>
            <div className={(open ? "translate-y-[100px]" : "-translate-y-full") +' transition-transform duration-[500ms] z-20 flex flex-col bg-primary-normal fixed w-full'}>
                {link.map(link => <Lnk onClick={() => setOpen(false)} className="hover:bg-primary-dark h-fit text-white no-underline px-[10px] py-[15px]" key={link.url} to={link.url}>{link.name}</Lnk>)}
                <hr className='border-primary-light'/>
                { !connected
                ?
                <>
                <Lnk onClick={() => setOpen(false)} className="hover:bg-primary-dark h-fit text-white no-underline px-[10px] py-[15px]" to='/login'>{t('header.profile.login')}</Lnk>
                <Lnk onClick={() => setOpen(false)} className="hover:bg-primary-dark h-fit text-white no-underline px-[10px] py-[15px]" to='/signin'>{t('header.profile.signin')}</Lnk>
                </>
                :
                <>
                <Lnk onClick={() => setOpen(false)} className="hover:bg-primary-dark h-fit text-white no-underline px-[10px] py-[15px]" to='/profile'>{t('header.profile.profile')}</Lnk>
                </>
                }
            </div>
        </>
    );
}

export default Header;