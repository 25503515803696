import React from 'react';
import { Link } from 'react-router-dom';

export default function Search(props) {

    const callback = (ev) => {
        if(ev.key !== "Enter") return
        props.onSearch(ev.target.value)
    }

  return (
    <div>
        <input onKeyDown={callback}/>
    </div>
    )
}
