import { useEffect } from 'react';
import useFetchData from '../api/useFetchData'
import Loader from '../components/Spinner';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Input from '../components/Input';
import Button from '../components/Button';

function Login() {
    const navigate = useNavigate()

    const { t } = useTranslation();
    const {data, error, loading, fetchPOSTData} = useFetchData('account/auth');
    
    const useForm = (event) => {
        event.preventDefault();
        
        var {uname, pass} = document.forms[0];

        fetchPOSTData({username: uname.value, password: pass.value})
    }

    useEffect(() => {
			if(data?.code === 200){
				localStorage.setItem('token', data.token);
			navigate("/profile/");
        }
    }, [data,navigate])

    return (
        <div className="p-[20px]">
            <form onSubmit={useForm}>
                <div className='shadow-header flex flex-col bg-white p-[20px] gap-[15px] rounded-[5px]'>
                    <h1 className='font-medium text-text'>{t('form.username')}</h1>
                    <Input name="uname"></Input>
                    <h1 className='font-medium text-text'>{t('form.password')}</h1>
                    <Input name="pass" type="password"></Input>
                    <Button type="full" onClick={() => console.log('test')} className="w-full">Confirm</Button>
                </div>
            </form>
            {loading && <Loader/>}
        </div>
    )
}

export default Login;
