import Root from '../page/Root';
import Homepage from '../page/Homepage';
import NotFound from '../page/NotFound';
import Offers from '../page/Offers';
import Login from '../page/login';
import Profile from './profile';
import Admin from './admin';
import { createBrowserRouter } from 'react-router-dom';
import PersistantUser from '../components/PersistantUser';
import Logout from '../page/Logout';

const router = createBrowserRouter([
  {
    path: '/',
    element: <PersistantUser />,
    children: [
      {
        path: '/',
        element: <Root />,
        children: [
          {
            path: '/*',
            element: <NotFound />
          },
          {
            path: '/',
            element: <Homepage />
          },
          {
            path: '/offers',
            element: <Offers />
          },
          {
            path: '/offers/:id',
            element: <Offers />
          },
          {
            path: '/login',
            element: <Login />
          },
          {
            path: '/logout',
            element: <Logout />
          }
        ]
      },
      Profile,
      Admin
      ]
    }
  ])
  
export default router;