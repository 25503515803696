import React from 'react';
import { Link } from 'react-router-dom';

export default function Button(props) {
    let className = props.className + " block w-fit h-fit border-2 font-medium no-underline border-solid px-[10px] py-[5px] rounded-[5px] ";

    switch(props.type){
        case 'white':
            className += 'text-primary-normal border-white bg-white ';
            className += 'hover:bg-text hover:border-text ';
            break;
        case 'full':
            className += 'text-white border-primary-normal bg-primary-normal ';
            className += 'hover:bg-primary-dark hover:border-primary-dark '
            break;
        default:
            className += 'text-text border-text ';
            className += 'hover:border-text-light hover:text-primary-normal ';
            break;
    }

    if(props.big){
        className += 'px-[20px] py-[10px] rounded-[5px] text-[32px] ';
    }

  return (
    <>
    { props.onClick ? 
        <button onClick={props.onClick} className={className}>{props.children}</button>
    :
        <Link className={className} to={props.to}>{props.children}</Link>
    }
    </>
    )
}
