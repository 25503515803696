import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useStoreState } from 'easy-peasy';

function Account(props) {
    const { t } = useTranslation();

    const userData = useStoreState((state) => state.user.data)

    return (
        <>
            <h1 className='text-[36px] font-bold text-primary'>{t('profile.page.account')}</h1>
            <div className='flex gap-[20px]'>
                <div className='flex gap-[10px] flex-col'>
                    <h1 className='font-medium text-[36px] text-text'>Personal information</h1>
                    <div className='shadow-header flex flex-col bg-white p-[20px] gap-[15px] rounded-[5px]'>
                        <h1 className='font-medium text-text'>Email</h1>
                        <Input></Input>
                        <h1 className='font-medium text-text'>Current password</h1>
                        <Input></Input>
                        <h1 className='font-medium text-text'>New password</h1>
                        <Input></Input>
                        <Button type="full" className="w-full">Confirm</Button>
                    </div>
                </div>
                <div className='flex gap-[10px] flex-col'>
                    <h1 className='font-medium text-[36px] text-text'>Balance</h1>
                    <div className='shadow-header flex flex-col bg-white p-[20px] gap-[15px] rounded-[5px]'>
                        <h1 className='font-medium text-text'>Add amount</h1>
                        <Input></Input>
                        <Button type="full" className="w-full">Generate bill</Button>
                    </div>
                </div>

                <div className='flex gap-[10px] flex-col'>
                    {userData?.grade === 1 && <>
                        <h1 className='font-medium text-[36px] text-text'>Admin</h1>
                        <div className='shadow-header flex flex-col bg-white p-[20px] gap-[15px] rounded-[5px]'>
                            <Button type="full" to="/admin/" className="w-full">Go</Button>
                        </div></>
                    }
                </div>

            </div>
        </>
    );
}

export default Account;