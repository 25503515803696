import { action } from 'easy-peasy';

const commands = {
    filter: {filter: ''},
    setFilter: action((state, payload) => {
        state.filter.filter = payload;
    })
};

export default commands;

