import {useEffect} from 'react';
import { Outlet, useLocation } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';

function Root() {
  let location = useLocation().pathname;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div className="flex flex-col App min-h-screen bg-background font-outfit">
      <Header />
      <div className='grow flex'>
        <Outlet />
      </div>
      
      <Footer />
    </div>
  );
}

export default Root;
