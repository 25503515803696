import Profile from '../page/Profile';

import Account from '../page/profile/Account';
import Products from '../page/profile/Products';
import NotFound from '../page/NotFound';

const routes = {
    path: '/profile',
    element: <Profile />,
    children: [
        {
        path: '/profile/*',
        element: <NotFound />
        },
        {
        path: '/profile/',
        element: <Account />
        },
        {
            path: '/profile/products',
            element: <Products />
        },
    ]
}

export default routes;